import React, { useState, useEffect } from 'react';
import Gif1 from './../img/gif1.gif'
import coin from './../img/coin.png'
import walletImg from './../img/wallet.png'
import TONimg from './../img/tnd.png'
import * as SolarIconSet from "solar-icon-set";
import moreTON_1 from './../img/moreTON_1.png'
import checkgris from './../img/check_gris.png'
import check_azul from './../img/check_azul.png'

import imgMagic from './../img/magic.png'
import cofre_azul from './../img/cofre_azul.png'
import cofre_amarillo from './../img/cofre_amarillo.png'

import axios from 'axios';
import Countdown from './CountDown';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css'; // Importar el CSS para los estilos
import { useUtils } from '@telegram-apps/sdk-react';
import { TonConnectButton, useTonWallet, useTonAddress} from '@tonconnect/ui-react';
import { useInitData, useLaunchParams } from '@telegram-apps/sdk-react';
import {
  Avatar,
  Cell,
  List,
  Navigation,
  Placeholder,
  Section,
  Text,
  Title,
} from '@telegram-apps/telegram-ui';


const Toast = Swal.mixin({
  toast: true,
  position: 'center',
  iconColor: 'white',
  customClass: {
    popup: 'colored-toast',
  },
  showConfirmButton: false,
  timer: 1500,
  timerProgressBar: true,
})


const ToastExito = Swal.mixin({
  toast: true,
  position: 'center',
  // iconColor: '',
  imageUrl: 'icons_/exito-icon.png', // URL del ícono personalizado
  imageWidth: 24,  // Ancho del ícono
  imageHeight: 24,  // Alto del ícono
  imageAlt: 'Custom icon',  // Texto alternativo para la imagen
  customClass: {
    popup: 'colored-toast',
  },
  showConfirmButton: false,
  showCloseButton: true,  // Muestra el ícono de cerrar
  timer: 1500,
  timerProgressBar: true,
})


const ToastError = Swal.mixin({
  toast: true,
  position: 'center',
  // iconColor: '',
  imageUrl: 'icons_/close-icon.png', // URL del ícono personalizado
  imageWidth: 24,  // Ancho del ícono
  imageHeight: 24,  // Alto del ícono
  imageAlt: 'Custom icon',  // Texto alternativo para la imagen
  customClass: {
    popup: 'colored-toast',
  },
  showConfirmButton: false,
  showCloseButton: true,  // Muestra el ícono de cerrar
  timer: 2147483647,
  timerProgressBar: true,
})

const ToastInfo = Swal.mixin({
  toast: true,
  position: 'center',
  // iconColor: '',
  imageUrl: 'icons_/info-icon.png', // URL del ícono personalizado
  imageWidth: 24,  // Ancho del ícono
  imageHeight: 24,  // Alto del ícono
  imageAlt: 'Custom icon',  // Texto alternativo para la imagen
  customClass: {
    popup: 'colored-toast',
  },
  showConfirmButton: false,
  showCloseButton: true,  // Muestra el ícono de cerrar
  timer: 2147483647,
  timerProgressBar: true,
})

const ToastAlarma = Swal.mixin({
  toast: true,
  position: 'center',
  // iconColor: '',
  imageUrl: 'icons_/alarma-icon.png', // URL del ícono personalizado
  imageWidth: 24,  // Ancho del ícono
  imageHeight: 24,  // Alto del ícono
  imageAlt: 'Custom icon',  // Texto alternativo para la imagen
  customClass: {
    popup: 'colored-toast',
  },
  showConfirmButton: false,
  showCloseButton: true,  // Muestra el ícono de cerrar
  timer: 2147483647,
  timerProgressBar: true,
})

const Home = () => {
  const wallet = useTonWallet();
  const utils = useUtils();
  const initData = useInitData();
  const userFriendlyAddress = useTonAddress();


    const [tasks, setTasks] = useState([]);
    const [balance, setBalance] = useState(0);
    const [loading, setLoading] = useState(false);

    const [telegramId, setTelegramId] = useState(initData.user.id);
    const [isFarming, setIsFarming] = useState(false);
    const [claimTimeLeft, setClaimTimeLeft] = useState(0);
    const [timeLeft,settimeLeft] = useState(null)
    const [farming, setFarming] = useState(false);
    const [username, setUsername] = useState(false);
    const [direccion, setDireccion] = useState(null);
    const [modalA, setModalA] = useState(false);
    const [boxModal, setBox] = useState(false);
    const [userStat,setStats] = useState(null)
    const [dataBox,setDataBox] = useState(null)
    const [coinsTotal,setCoinsTotal] = useState(null)
    const [tonsTotal,setTonsTotal] = useState(null)
    const [tonsClaimed,setTonsClaimed] = useState(null)

    const [message, setMessage] = useState('');
    const [inviteLink, setInviteLink] = useState('');
    const [esMiembro,setEsMiembro] = useState(false)

    const fetchBalance = async () => {
      setLoading(true)
        try {
          const response = await axios.get(`https://bot-api.critterskombat.com/balance/${telegramId}`);
          setBalance(response.data.balance);
          setUsername(response.data.username)
          console.log(response.data,'response.data')
          setCoinsTotal(response.data.total_coins)
          setTonsTotal(response.data.total_tons)
          setTonsClaimed(response.data.ton_claimed)
          setLoading(false)
        }  catch (error) {
          setLoading(false)
          if (error.response && error.response.status === 403) {
            // Si el usuario no es miembro, muestra el mensaje y el enlace de invitación
            setMessage(error.response.data.message);
            setInviteLink(error.response.data.inviteLink);
          } else {
            setMessage('Error al obtener el balance. Por favor, intenta nuevamente.');
          }
        }
        
    };

    
    const fetchInicial = async () => {

      setLoading(true)
      try {
        const response = await axios.get(`https://bot-api.critterskombat.com/farming-status/${telegramId}`);
        console.log('response',response)
        setFarming(response.data.active)
        settimeLeft(response.data.timeLeft)
        // setTasks(response.data);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
      setLoading(false)
    };

    const startFarming = async () => {
        try {
          setLoading(true)
          const response = await axios.post('https://bot-api.critterskombat.com/start-farming', { telegramId });
          settimeLeft(response.data.farmingDuration)

          setIsFarming(true);
          setFarming(false)
          fetchInicial();
          setClaimTimeLeft(10);

          await ToastExito.fire({
            title: 'Farming started',
          })

          // await Toast.fire({
          //   icon: 'success',
          //   title: 'Farming started',
          // })

          const timer = setInterval(() => {
            setClaimTimeLeft(prevTime => {
              if (prevTime <= 1) {
                clearInterval(timer);
                return 0;
              }
              return prevTime - 1;
            });
          }, 1000);
          setLoading(false)

        } catch (error) {
          console.error('Error starting farming:', error);
        }
      };

    const claimFarming = async () => {
        try {
          setLoading(true)
          await axios.post('https://bot-api.critterskombat.com/claim', { telegramId });
          fetchBalance();
          setIsFarming(false);
          setFarming(false)
          setLoading(false)
        } catch (error) {
          console.error('Error claiming farming:', error);
        }
    };

    useEffect(() => {
      console.log(initData.user,'utils')
        // Obtener telegramId de la URL
        const urlParams = new URLSearchParams(window.location.search);
        const telegramIdFromUrl = urlParams.get('telegramId');
        
        if (telegramIdFromUrl) {
          setTelegramId(initData.user.id);
          
        }
        else{
          setTelegramId(initData.user.id);
          setUsername(initData.user.username)

        }
 
        
      }, [initData]);



    useEffect(() => {
      if(initData.user.id){

        fetchInicial()
        fetchBalance();
        getesmiembro(initData.user.id)
      }
    }, []);


    const saveWallet = async (telegramId,newWallet) =>{
      setLoading(true)
      await axios.put('https://bot-api.critterskombat.com/update-wallet', { telegramId, newWallet });
      setLoading(false)
    }


    const userReporte = async (telegramId) =>{
      setLoading(true)
      let reporte = await axios.get(`https://bot-api.critterskombat.com/user-report/${telegramId}`);
      setStats(reporte.data)
      console.log(reporte,'reporte')
      setLoading(false)
    }

    const dailyDraw = async (telegramId) =>{
      setLoading(true)
      try {
        let reporte = await axios.post(`https://bot-api.critterskombat.com/daily-draw/`,{telegramId});
        setDataBox(reporte.data)
        setLoading(false)
        console.log(reporte,'reporte')
      } catch (error) {
        console.log(error)
        if (error.response && error.response.status === 403) {
          ToastError.fire({
            title: error.response.data,
          })
          setLoading(false)
        } else {
          setMessage('Error al obtener el balance. Por favor, intenta nuevamente.');
        }
      }

    }

    const getesmiembro = async (telegramId) =>{
      setLoading(true)
      try {
        let reporte = await axios.get(`https://bot-api.critterskombat.com/isMember/${telegramId}`);
        setEsMiembro(reporte.data.isMember)
        setLoading(false)
      } catch (error) {
        console.log(error,'error')
        setEsMiembro(false)
        setLoading(false)

      }
    }



    useEffect(()=>{
      // alert(wallet)
      console.log(wallet,'wallet')
      if(wallet && wallet !== null && wallet !== 'null'){
        const {
          account: { chain, publicKey, address },
          device: {
            appName,
            appVersion,
            maxProtocolVersion,
            platform,
            features,
          },
        } = wallet;
        console.log(wallet,'wallet')
        // saveWallet(telegramId,address)
        // setDireccion(address)
      }
    },[wallet])


    useEffect(()=>{{
      if(userFriendlyAddress){
        saveWallet(telegramId,userFriendlyAddress)
        setDireccion(userFriendlyAddress)
      
      }
    }},[userFriendlyAddress])
    
    return (
        <div className='first-div'>
          {
            loading && 
            <div className='spin_wrap'>
              <span class="loader_spin"></span>
            </div>
          }
            <div id='modal_invite_blur' className={modalA ? 'active' : ''}></div>
            <div id='modal_bonus_blur' className={boxModal ? 'active' : ''}></div>
            <div id='modal_invite_blur' className={!esMiembro ? 'active' : ''}></div>

            <div id='modal_box' className={boxModal ? 'wrap_container active' : 'wrap_container'}>
                <div className='item-container-blue-box main-modal-ton'>
                  <div className='wrap_close_ton'>
                    <div className='cursor' onClick={()=>setBox(false)}>
                      <SolarIconSet.CloseCircle color="#fff" size={24} iconStyle="Outline" />
                      </div>
                  </div>
                    <div className='title_modal_style1'>
                      <div className='wrap_cofre'>
                        {
                          dataBox?.prize_type === 'TON' ? <img src={cofre_azul} alt="" /> : <img src={cofre_amarillo} alt="" />
                        }   
                      <p>Your Bonus</p>
                      </div>
                    </div>
                    {/* <p>Earn a bonus by BOXg your tasks!</p> */}
                    <div className='btns_bonus_cofre'>
                      <div 
                      className='button_uno_cofre'>
                        {
                          dataBox?.prize_type === 'TON' ? <img src={TONimg} width={24} alt="" /> : <img src={coin} width={24} alt="" />
                        }
                        
                        <p>{dataBox?.prize_amount} {dataBox?.prize_type}</p>
                      </div>
                      <div 
                      onClick={()=>{
                        setBox(false)

                        fetchBalance()
                      }}
                      className='button_bonus_claim'>
                        <p>Claim Bonus</p>
                      </div>
                    </div>
                   
                    
                 </div>
            </div>

            <div id='modal_invite' className={!esMiembro ? 'wrap_container active' : 'wrap_container'}>
                <div className='item-container-blue main-modal-ton'>
                  <div className='wrap_close_ton'>
                    <div className='cursor' onClick={()=>setModalA(false)}>
                      <SolarIconSet.CloseCircle color="#fff" size={24} iconStyle="Outline" />
                      </div>
                  </div>
                    <div className='title_modal_style1'>
                      <div>
                      <p style={{marginTop:"30px"}}>To start you must follow us on our telegram channel and reload.</p>
                      </div>
                    </div>
                    
                   
                 
                      
                      <>
                      <div 
                      onClick={()=>{
                        window.location.href = "https://t.me/CrittersKombat";
                      }}
                      className='button_openbox_active'>
                        <p>Join Now</p>
                      </div>
                      <div 
                      onClick={()=>{
                        window.location.reload();
                      }}
                      className='button_openbox'>
                        <img src={imgMagic} alt="" />
                        <p>
                        I have Joined</p>
                      </div>
                      </>
                    
                 </div>
            </div>

            <div id='modal_invite' className={modalA ? 'wrap_container active' : 'wrap_container'}>
                <div className='item-container-blue main-modal-ton'>
                  <div className='wrap_close_ton'>
                    <div className='cursor' onClick={()=>setModalA(false)}>
                      <SolarIconSet.CloseCircle color="#fff" size={24} iconStyle="Outline" />
                      </div>
                  </div>
                    <div className='title_modal_style1'>
                      <div>
                      <img src={moreTON_1} alt="" />
                      {/* <p>Earn more TON!</p> */}
                      </div>
                    </div>
                    <p className='txt_white'>Earn a bonus by completing your tasks!</p>
                    <div className='items_completed'>
                      <div className='item-completed-un'>
                        <div className='text_item_completed'>
                          <p>Completed Farming</p>
                          <p>{userStat?.farming_count_today + ' / '+ userStat?.farming_limit_per_day }</p>
                        </div>
                        <div className='check_gris'>
                          {
                            userStat?.farming_count_today >=  userStat?.farming_limit_per_day ? <img src={check_azul} alt="" /> :<img src={checkgris} alt="" />
                          }
                          
                        </div>
                      </div>
                    </div>
                    <div className='items_completed'>
                      <div className='item-completed-un'>
                        <div className='text_item_completed'>
                          <p>Pending Quests</p>
                          <p>{userStat?.pending_tasks_count}</p>
                        </div>
                        <div className='check_gris'>
                          {
                            userStat?.pending_tasks_count == 0 ? <img src={check_azul} alt="" /> : <img src={checkgris} alt="" /> 
                          }
                          
                        </div>
                      </div>
                    </div>
                    {
                      userStat?.pending_tasks_count == 0 &&  userStat?.farming_count_today >=  userStat?.farming_limit_per_day ? 
                      
                      <div 
                      onClick={()=>{
                        setBox(true)
                        setModalA(false)
                        dailyDraw(initData.user.id)
                      }}
                      className='button_openbox_active'>
                        <img src={imgMagic} alt="" />
                        <p>
                          Open Box</p>
                      </div>
                      :
                      <div 
                      
                      className='button_openbox'>
                        <img src={imgMagic} alt="" />
                        <p>
                          Open Box</p>
                      </div>
                    }
                 </div>
            </div>
            <div>
              <div className='puntos_header'>
                  <div className='saludo_main'>
                      <p>Welcome</p>
                      <strong>{username ? username : telegramId }</strong>
                  </div>
                  <div className='wrap_points'>
                  {
                  !wallet && 
                  <Placeholder
                    className='ton-connect-page__placeholder'
                    header=''
                    description={
                      <>
                        
                        <TonConnectButton className='ton-connect-page__button'/>
                      </>
                    }
                  />
                  
                  }
                      {
                        wallet && 
                        <div 
                        onClick={()=>{
                          setDireccion(null)
                        }}
                        className='btn-connect-w'>
                          <img src={walletImg} alt="" />
                          {
                            direccion ? <p>{direccion?.slice(0, 4) +'...' +direccion?.slice(-4)}</p> : <p>..</p>
                          }
                         
                        </div>
                      }
                  </div>
                  
              </div>
              <div class="puntos_header"> 
                  
                  {
                    wallet ? 
                    <div className='header_cp_'>
                        <div className='points_circle'>
                          <span>CP earned</span>
                          <div className='points-coin'>
                          <img src={coin} width={24} alt="" />
                          <p>  {coinsTotal}</p>
                          </div>
                        </div>
                        <div onClick={()=>{
                          userReporte(initData.user.id);
                          setModalA(true)
                          
                          fetchBalance()
                        }} className='points_circle earn_ton_btn'>
                          <div className='points-coin'>
                          <img src={TONimg} width={24} alt="" />
                          <span>Earn TON</span>
                          </div>
                        </div>
                        <div className='points_circle'>
                          <span>TON earned</span>
                          <div className='points-coin'>
                          <img src={TONimg} width={24} alt="" />
                          <p>  {tonsTotal}</p>
                          </div>
                        </div>
                        <div className='points_circle'>
                          <span>TON Claimed</span>
                          <div className='points-coin'>
                          <img src={TONimg} width={24} alt="" />
                          <p>  {tonsClaimed}</p>
                          </div>
                        </div>
                    </div> : 
                    <div className='wrap_points_s'>
                        <div className='points_circle'>
                          <span>CP earned</span>
                          <div>
                          <img src={coin} width={24} alt="" />
                          <p>  {balance}</p>
                          </div>
                        </div>
                    </div>
                    
                  }
              </div>
            </div>
            <div className='farming-btns'>
                {/* <img src={Gif1} alt="" /> */}
                
                <div className='bg_kit'>

                </div>
                {
                    timeLeft ?
                    <div className='wrap_container'>
                        <div className='item-container-blue earn_ton'>
                            <div className='btn_farming_time' > 
                                <div className='progress_time'>
                                    <p>Farming </p>
                                    <img src={coin}  width={24}  alt="" />
                                </div>
                            <Countdown settimeLeft={settimeLeft} initialSeconds={Math.floor(timeLeft)}/>
                            </div>
                        </div>
                    </div>   : ''
                }
                <div className="wrap_container">
                    {
                        !farming && 
                        <div className='item-container-blue'>
                            <div className='btn_farming' onClick={startFarming} disabled={isFarming}> 
                                <p>Start farming</p>  
                            </div>
                        </div>
                    }


                    

                    {
                        farming &&  timeLeft == 0 ? 
                        <div className='item-container-blue'>
                            <div 
                            onClick={claimFarming} disabled={!isFarming || claimTimeLeft > 0}
                            className='btn_farming'> 
                                <p>Claim</p>
                            </div>
                        </div> : ''

                    }
                    
                    
                    {/* <button >Reclamar Monedas</button> */}
                </div>
            </div>
            <div className='wrapVideo'>
            <video autoPlay muted loop playsInline class="background-video">
              <source src="/video/bg.mp4" type="video/mp4"/>
              Tu navegador no soporta el video.
          </video>
            </div>
        </div>
    );
};

export default Home;