import React, { useState, useEffect } from 'react';
import * as SolarIconSet from "solar-icon-set";
import { Routes, Route, Link } from 'react-router-dom';
import { useUtils, useInitData} from '@telegram-apps/sdk-react';

const Menu = () => {
    const [telegramId, setTelegramId] = useState('');
    const utils = useUtils();
    const initData = useInitData();

    useEffect(() => {
        // Obtener telegramId de la URL
        const urlParams = new URLSearchParams(window.location.search);
        const telegramIdFromUrl = urlParams.get('telegramId');
        if (telegramIdFromUrl) {
        //   setTelegramId(telegramIdFromUrl);
        setTelegramId(initData.user.id)
        }
        else{
            setTelegramId(initData.user.id)
        }
      }, [initData]);
    return (
        <div className='wrap_c_menu'>
            <div className='container_menu'>
                <Link to={`/ `}>
                    <div className='item-menu'>
                            <SolarIconSet.HomeSmile color="#fff" size={24} iconStyle="Outline" />
                            <span>Home</span>
                    </div>
                </Link>
                <Link to={`/quests `}>
                    <div className='item-menu'>
                        <SolarIconSet.Gift color="#fff" size={24} iconStyle="Outline" />
                        <span>Quests</span>
                    </div>
                </Link>
                <Link to={`/roadmap `}>
                    <div className='item-menu'>
                        <SolarIconSet.Calendar color="#fff" size={24} iconStyle="Outline" />
                        <span>Roadmap</span>
                    </div>
                </Link>

                <Link to={`/referrals `}>
                    <div className='item-menu'>
                        <SolarIconSet.StarCircle color="#fff" size={24} iconStyle="Outline" />
                        <span>Referrals</span>
                    </div>
                </Link>
                
            </div>
        </div>
    );
};

export default Menu;