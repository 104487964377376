import React, { useState, useEffect } from 'react';
import * as SolarIconSet from "solar-icon-set";

const Countdown = ({ initialSeconds, settimeLeft}) => {
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);


  useEffect(()=>{
    settimeLeft(seconds)
  },[seconds])

  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };


  return (
    <div>
      {
         seconds % 60 < '0' ? <p className='time_p'> <SolarIconSet.ClockCircle color="#fff" size={24} iconStyle="Outline" /> 00:00:00</p> :<p className='time_p'>  <SolarIconSet.ClockCircle color="#fff" size={24} iconStyle="Outline" /> {formatTime(seconds)}</p>
      }
      
    </div>
  );
};

export default Countdown;