import React from 'react';
import Gif1 from './../img/gif1.gif'
import calendario from './../img/calendario.png'
import * as SolarIconSet from "solar-icon-set";

const Roadmap = () => {

    let listItems = [
        {
            date:'July 2024',
            desc:'Social media launch',
            active:true
        },
        {
            date:'July 2024',
            desc:'Airdrop game',
            active:true
        },
        {
            date:'September 15, 2024 - 20:00UTC',
            desc:'Pre-sale'
        },
        {
            date:'October 2024 ',
            desc:'TGE and Stacking'
        },
        {
            date:'November 2024',
            desc:'Official games'
        },
        {
            date:'December 2024',
            desc:'Launch of marketplace for players'
        },
        {
            date:'December 2024',
            desc:'Implementation of new features and special events'
        }
    ]

    return (
        <div>
            
            <div className='header_screen'>
                <img src={calendario} alt="" />
                <h2 className='title_screen'>Roadmap</h2>
                <p className='sub_screen'>Our Path to Success!</p>
            </div>


            <div className='quest_list_wrap'>
                {
                    listItems.map((item)=>(
                        <div className='item_quest_list'>
                            <div className='title_item_quest'>
                                <div className={item.active ? 'circle_active' : 'circle_desactive' } >
                                <SolarIconSet.CheckCircle color="#fff" size={24} iconStyle="Outline" />
                                </div>
                                <div className='item_title_item_roadmap'>
                                    <p>{item.date}</p>
                                    <span className='sub_item_'>
                                       {item.desc}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default Roadmap;