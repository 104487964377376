import React, { useState, useEffect } from 'react';
import star from './../img/star.png'
import coin from './../img/coin.png'
import axios from 'axios';
import * as SolarIconSet from "solar-icon-set";
import { useUtils, useInitData} from '@telegram-apps/sdk-react';

const Referreals = () => {
    const [telegramId, setTelegramId] = useState('');
    const utils = useUtils();
    const initData = useInitData();

    const [referidos, setReferidos] = useState([]);
    const [modalA, setModalA] = useState(false);
    const [loading, setLoading] = useState(false);


    const fetchTasks = async (t) => {
        try {
            setLoading(true)
          const response = await axios.get(`https://bot-api.critterskombat.com/referrals/${t}`);
          setLoading(false)
          setReferidos(response.data);
        } catch (error) {
            console.log(error,'error')
            setLoading(false)
          console.error('Error fetching tasks:', error);
        }
      };
    useEffect(() => {
        // Obtener telegramId de la URL
        const urlParams = new URLSearchParams(window.location.search);
        const telegramIdFromUrl = urlParams.get('telegramId');
        if (telegramIdFromUrl) {
        //   setTelegramId(telegramIdFromUrl);
        setTelegramId(initData.user.id)
        }
        else{
            setTelegramId(initData.user.id)

        }
        fetchTasks(initData.user.id)
    }, [initData]);

    const handleCopy = async (content) => {
        try {
          await navigator.clipboard.writeText(content);
          console.log('Copied to clipboard:', content);
        } catch (error) {
          console.error('Unable to copy to clipboard:', error);
        }
      };
    return (
        <div>
            {
              loading && 
              <div className='spin_wrap'>
                <span class="loader_spin"></span>
              </div>
            }
            <div id='modal_invite_blur' className={modalA ? 'active' : ''}>

            </div>
            
            <div id='modal_invite' className={modalA ? 'wrap_container active' : 'wrap_container'}>
                <div className='item-container-blue'>
                    <div className='title_modal_style1'>
                        <p>Invite Friends</p>
                        <div className='cursor' onClick={()=>setModalA(false)}>
                        <SolarIconSet.CloseCircle color="#fff" size={24} iconStyle="Outline" />
                        </div>
                    </div>
                    <div onClick={()=>handleCopy(`https://t.me/CrittersKombatBot?start=${telegramId}`)} className='btn_farming_dos'>
                            <p>Copy link</p>
                        </div>
                 </div>
            </div>
            
            <div className='header_screen'>
                <img src={star} alt=""  />
                <h2 className='title_screen'>Referreals</h2>
                <p className='sub_screen'>Score 500 CP for each friend you invite</p>
            </div>


            


            <div className="wrap_container">
                    <div>
                        <div className='line-friends'>
                            <div>
                                <p>Friends <span>{referidos.length}</span></p>
                            </div>
                            <div>
                                <p style={{display:'flex', gap:'5px'}}><img width={24} src={coin} alt="" />  CP earned: <span>{referidos.length*500}</span> </p>
                            </div>
                        </div>
                    <div className='item-container-blue'>
                        <div 
                        onClick={()=>(setModalA(true))}
                        className='btn_farming'>
                            <p>Invite friend</p>
                        </div>
                    </div>
                    </div>
            </div>

            <div className='quest_list_wrap_ref'>
                {
                    referidos?.map((item)=>(
                    <div className='item_quest_list'>
                        <div className='title_item_quest'>
                            <div>
                                <SolarIconSet.UserCircle color="#fff" size={24} iconStyle="Outline" />
                            </div>
                            <div>
                                <p>{item.username}</p>
                            </div>
                        </div>
                    </div>
                    ))
                }
                
            </div>

            {/* <div className="wrap_container">
                    <div className='item-container-blue'>
                        <div className='btn_farming_blue'>
                            {/* <p>Claim</p> */}
                            {/* <img src={coin} alt="" /> */}
                            {/* <p>{referidos.reduce((total, objeto) => total + objeto.reward_coins, 0)}                            </p> */}
                        {/* </div> */}
                    {/* // </div> */}
            {/* </div> */}
        </div>
    );
};

export default Referreals;