import React, { useState, useEffect, useMemo} from 'react';
import './App.css';
import Home from './components/Home';
import Menu from './components/Menu'
import Quest from './components/Quests'
import Roadmap from './components/Roadmap';
import Referreals from './components/Referreals';
import { Routes, Route, Link } from 'react-router-dom';
import Loader from './components/Loader';

import { SDKProvider, useLaunchParams } from '@telegram-apps/sdk-react';
import { TonConnectUIProvider } from '@tonconnect/ui-react';



function HomeLoad () {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simular la carga de datos o alguna operación
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000); // Cambia el tiempo según sea necesario
  
    return () => clearTimeout(timer); // Limpiar el temporizador si el componente se desmonta
  }, []);
  return(
    loading ? <Loader /> :      <Routes>
    <Route path="/" element={<Home />} />
    {/* <Route path="/about" element={<About />} /> */}
    <Route path="/roadmap" element={<Roadmap />} />
    <Route path="/quests" element={<Quest />} />
    <Route path="/referrals" element={<Referreals />} />
  </Routes>
  )
}

function App() {
  const debug = useLaunchParams().startParam === 'debug';
  const manifestUrl = useMemo(() => {
    return new URL('tonconnect-manifest.json', window.location.href).toString();
  }, []);

  // Enable debug mode to see all the methods sent and events received.
  useEffect(() => {
    // if (debug) {
    //   import('eruda').then((lib) => lib.default.init());
    // }
  }, [debug]);
  return (
    <TonConnectUIProvider manifestUrl={'https://airdrop.critterskombat.com/tonconnect-manifest.json'}>
      <SDKProvider acceptCustomStyles debug={debug}>
      <div className="App">
        <HomeLoad/>
        <Menu/>
      </div>
      </SDKProvider>
    </TonConnectUIProvider>

  );
}

export default App;
