import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUtils, useInitData} from '@telegram-apps/sdk-react';

const QuestsItem = ({task}) => {
    const [telegramId, setTelegramId] = useState('');
    const utils = useUtils();
    const initData = useInitData();
    const [loading, setLoading] = useState(false);

    const [estado,setEstado] = useState(task.status)

    const updateCheking = async (taskId) => {
        try {
          setLoading(true)
          await axios.post('https://bot-api.critterskombat.com/update-task-to-checking', { telegramId, taskId });
          setLoading(false)
          setEstado('checking')
          // fetchBalance();
        } catch (error) {
          setLoading(false)
          console.error('Error completing task:', error);
        }
    };
    
    const updateDone = async (taskId) => {
        try {
          setLoading(true)
          await axios.post('https://bot-api.critterskombat.com/update-task-to-done', { telegramId, taskId });
          setLoading(false)
          setEstado('done')
          // fetchBalance();
        } catch (error) {
          console.error('Error completing task:', error);
        }
    };

    useEffect(() => {
        // Obtener telegramId de la URL
        const urlParams = new URLSearchParams(window.location.search);
        const telegramIdFromUrl = urlParams.get('telegramId');
        if (telegramIdFromUrl) {
          // setTelegramId(telegramIdFromUrl);
          setTelegramId(initData.user.id)
        }
        else{
          setTelegramId(initData.user.id)
        }
      }, [initData]);

      useEffect(() => {
        if (telegramId) {
        //   fetchTasks();
        }
      }, [telegramId]);
    return (
        <div>
            {
              loading && 
              <div className='spin_wrap'>
                <span class="loader_spin"></span>
              </div>
            }
            <div className='item_quest_list'>
                      <div className='title_item_quest'>
                          <div>
                              {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path d="M17.7508 2.96082H20.8175L14.1175 10.6192L22 21.0383H15.8283L10.995 14.7183L5.46333 21.0383H2.395L9.56167 12.8467L2 2.96166H8.32833L12.6975 8.73832L17.7508 2.96082ZM16.675 19.2033H18.3742L7.405 4.69999H5.58167L16.675 19.2033Z" fill="#FCFDFF"/>
                              </svg> */}
                          </div>
                          <div>
                              <a>
                                <p>{task.name}</p></a>
                              <p>
                              <img width={24} src={`https://app.critterskombat.com/icons_/${task.icon}.png`} alt="" />
                              {task.description}</p>
                          </div>
                      </div>
                      <div className='wrap_btns_quest'>
                          <div>
                            
                              <p className='monedas'>+{task.reward_coins} CP</p>
                          </div>
                          {
                            estado == 'pending' ?
                            <div onClick={()=>{
                              updateCheking(task.id)
                              window.open(task.link, "_blank")
                            }} className='pending'>
                                <p>{estado}</p>
                            </div> 
                            : ''
                          }

                          {
                            estado == 'claim' ?
                            <div onClick={()=>updateDone(task.id)} className='pending'>
                                <p>{estado}</p>
                            </div> 
                            : ''
                          }

                          {
                            estado == 'checking' ?
                            <div className='pending'>
                                <p>{estado}</p>
                            </div> 
                            : ''
                          }

                            {
                            estado == 'done' ?
                            <div className='pending'>
                                <p>{estado}</p>
                            </div> 
                            : ''
                          }
                          
                          {/* <div onClick={() => completeTask(task.id)} className='pending'>
                          Marcar como completada
                          </div>  */}
                      </div>
                  </div>
        </div>
    );
};

export default QuestsItem;