import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUtils, useInitData} from '@telegram-apps/sdk-react';

import Gif1 from './../img/gif1.gif'
import check_secure from './../img/check_secure.png'
import QuestsItem from './QuestsItem';
const Quest = () => {
  const [telegramId, setTelegramId] = useState('');
  const [tasks, setTasks] = useState([]);
  const utils = useUtils();
  const initData = useInitData();
  const [loading, setLoading] = useState(false);

    const fetchTasks = async () => {
        try {
          setLoading(true)
          const response = await axios.get(`https://bot-api.critterskombat.com/user-tasks/${telegramId}`);
          setLoading(false)
          setTasks(response.data);
        } catch (error) {
          setLoading(false)
          console.error('Error fetching tasks:', error);
        }
      };

      const completeTask = async (taskId) => {
        try {
          setLoading(true)
          await axios.post('https://bot-api.critterskombat.com/complete-task', { telegramId, taskId });
          setLoading(false)
          // fetchBalance();
        } catch (error) {
          console.error('Error completing task:', error);
        }
      };






      useEffect(() => {
        // Obtener telegramId de la URL
        const urlParams = new URLSearchParams(window.location.search);
        const telegramIdFromUrl = urlParams.get('telegramId');
        if (telegramIdFromUrl) {
          // setTelegramId(telegramIdFromUrl);
          setTelegramId(initData.user.id)
        }
        else{
          setTelegramId(initData.user.id)
        }
      }, [initData]);

      useEffect(() => {
        if (telegramId) {
          fetchTasks();
        }
      }, [telegramId]);
    return (
        <div>
            {
              loading && 
              <div className='spin_wrap'>
                <span class="loader_spin"></span>
              </div>
            }
            <div className='header_screen'>
                <img src={check_secure} alt="" />
                <h2 className='title_screen'>Quests</h2>
                <p className='sub_screen'>Complete simple tasks and earn extra points</p>
            </div>


            <div className='quest_list_wrap'>
                {
                  tasks.map((task)=>(
                  <QuestsItem task={task}  />
                  ))
                }
            </div>
        </div>
    );
};

export default Quest;